import GRIGORA_APP from "../../../package.json";
import React, { createContext } from "react";
import { compareVersions } from "compare-versions";

const GrigoraVersioningContext = createContext();

const GrigoraVersioning = (props) => {
  function compareVersion(version) {
    return compareVersions(GRIGORA_APP.version, version) >= 0;
  }

  return (
    <GrigoraVersioningContext.Provider
      value={{
        version: GRIGORA_APP.version,
        versionName: GRIGORA_APP.versionName,
        compareVersion: compareVersion,
      }}
    >
      {props.children}
    </GrigoraVersioningContext.Provider>
  );
};
export { GrigoraVersioning, GrigoraVersioningContext };
