import React from "react";
import Loadable from "react-loadable";
import EmptyLoading from "../../components/empty-loading";

const OnboardingLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-onboarding" */
      /* webpackMode: "lazy" */
      "../onboarding"
    ),
  loading: EmptyLoading,
});

export default OnboardingLoadable;
